body {
  background-color: #222;
  color: #00ff00; /* Green text color */
}

.App {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  position: relative;
}

h1 {
  color: #00ff00; /* Green heading color */
  margin-left: -20px; /* Adjust margin-left value as needed */
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  margin-bottom: 10px;
}

.disclaimer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  overflow: auto;
  box-sizing: border-box; /* Include padding and borders in the width */
  margin: 0 10px; /* Add a margin to prevent content from touching the edges */
}

.disclaimer-content {
  max-width: 90%; /* Increase the max-width for better fit */
  text-align: center;
  margin-bottom: 20px;
}

.disclaimer button {
  background-color: #00ff00; /* Green button background color */
  color: #000; /* Black button text color */
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-size: 16px;
}

/* Add hover effect to the button */
.disclaimer button:hover {
  background-color: #00dd00; /* Darker green on hover */
}

.disclaimer h2 {
  margin-top: 0;
}

.disclaimer p {
  margin-bottom: 10px;
}

.top-right {
  position: fixed;
  top: 0;
  right: 100px; /* Adjust the right value to move the box to the left */
  width: 300px;
  background-color: #222; /* Use the same background color as body */
  color: #00ff00; /* Green text color */
  padding: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  text-align: right;
  z-index: 1;
}

.main-content {
  margin-top: 80px; /* Adjust margin-top value as needed */
}

.message-input-container {
  position: relative;
  z-index: 2; /* Adjust the z-index as needed */
}


.reply-item p {
  color: #00a2ff; /* Light blue text color */
}
